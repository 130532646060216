/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-19 16:53:14
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-09-20 20:31:01
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/getRemoteFile.js
 */
const http = require('http');

export function getRemoteFile(url, cb) {
    var callback = function () {
        // 回调函数，避免重复调用
        callback = function () {};
        cb.apply(null, arguments);
    };
  
    var req = http.get(url, function (res) {
        res.setEncoding('utf8');
        var b = [];
        res.on('data', function (c) {
            b.push(c);
        });
        res.on('end', function () {
            callback(null, b);
        });
        res.on('error', callback);
    });
    req.on('error', callback);
}

// export function Uint8ArrayToString(fileData){
//     var dataString = "";
//     // for (var i = 0; i < fileData.length; i++) {
//       dataString = String.fromCharCode.apply(null,fileData);
//     //   dataString = decodeURIComponent(escape(atob(dataString)));
//     // }
   
//     return dataString
// }