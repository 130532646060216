/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-02 19:21:44
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-10-15 15:47:31
 * @Description: AGC初始化
 * @FilePath: /ezpsy/src/assets/js/connectConfig.js
 */

import agconnect from "@agconnect/api";
import "@agconnect/instance";

/**
 * @description: 
 * @Date: 2022-09-02 20:28:29
 * @return {*}
 */
// export function getAgConnectConfig(){
	var context = 
	{
		"agcgw":{
			"backurl":"connect-drcn.hispace.hicloud.com",
			"url":"connect-drcn.dbankcloud.cn",
			"websocketbackurl":"connect-ws-drcn.hispace.dbankcloud.com",
			"websocketurl":"connect-ws-drcn.hispace.dbankcloud.cn"
		},
		"agcgw_all":{
			"CN":"connect-drcn.dbankcloud.cn",
			"CN_back":"connect-drcn.hispace.hicloud.com",
			"DE":"connect-dre.dbankcloud.cn",
			"DE_back":"connect-dre.hispace.hicloud.com",
			"RU":"connect-drru.hispace.dbankcloud.ru",
			"RU_back":"connect-drru.hispace.dbankcloud.cn",
			"SG":"connect-dra.dbankcloud.cn",
			"SG_back":"connect-dra.hispace.hicloud.com"
		},
		"websocketgw_all":{
			"CN":"connect-ws-drcn.hispace.dbankcloud.cn",
			"CN_back":"connect-ws-drcn.hispace.dbankcloud.com",
			"DE":"connect-ws-dre.hispace.dbankcloud.cn",
			"DE_back":"connect-ws-dre.hispace.dbankcloud.com",
			"RU":"connect-ws-drru.hispace.dbankcloud.ru",
			"RU_back":"connect-ws-drru.hispace.dbankcloud.cn",
			"SG":"connect-ws-dra.hispace.dbankcloud.cn",
			"SG_back":"connect-ws-dra.hispace.dbankcloud.com"
		},
		"client":{
			"cp_id":"30086000604203598",
			"product_id":"99536292102585513",
			"client_id":"971763283738513216",
			"client_secret":"703EC2029D51A0678B92BB1157DB3F05AD413ECB9D681DE37D0BD66B597FCF68",
			"project_id":"99536292102585513",
			"app_id":"243650030996314141",
			"api_key":"DAEDANkTsHauTx0+fPPl6zLjjN47ypEwSnUe9nKmtv2Umn0XBQrAeeOQZCvDiaZX/8BTJXM0xsGEdPoJ8lsanzvOl+hfdz3B1Dm0ZQ=="
		},
		"oauth_client":{
			"client_id":"107258407",
			"client_type":7
		},
		"app_info":{
			"app_id":"243650030996314141"
		},
		"service":{
			"analytics":{
				"collector_url":"datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn",
				"collector_url_ru":"datacollector-drru.dt.dbankcloud.ru,datacollector-drru.dt.hicloud.com",
				"collector_url_sg":"datacollector-dra.dt.hicloud.com,datacollector-dra.dt.dbankcloud.cn",
				"collector_url_de":"datacollector-dre.dt.hicloud.com,datacollector-dre.dt.dbankcloud.cn",
				"collector_url_cn":"datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn",
				"resource_id":"p1",
				"channel_id":""
			},
			"edukit":{
				"edu_url":"edukit.edu.cloud.huawei.com.cn",
				"dh_url":"edukit.edu.cloud.huawei.com.cn"
			},
			"search":{
				"url":"https://search-drcn.cloud.huawei.com"
			},
			"cloudstorage":{
				"storage_url_sg_back":"https://agc-storage-dra.cloud.huawei.asia",
				"storage_url_ru_back":"https://agc-storage-drru.cloud.huawei.ru",
				"storage_url_ru":"https://agc-storage-drru.cloud.huawei.ru",
				"storage_url_de_back":"https://agc-storage-dre.cloud.huawei.eu",
				"storage_url_de":"https://ops-dre.agcstorage.link",
				"storage_url":"https://agc-storage-drcn.platform.dbankcloud.cn",
				"storage_url_sg":"https://ops-dra.agcstorage.link",
				"storage_url_cn_back":"https://agc-storage-drcn.cloud.huawei.com.cn",
				"storage_url_cn":"https://agc-storage-drcn.platform.dbankcloud.cn",
				"default_storage":"ezpsy-qfqih"
			},
			"ml":{
				"mlservice_url":"ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn"
			}
		},
		"region":"CN",
		"configuration_version":"3.0"
	};
// }

export function configInstance(){
    // let context = getAgConnectConfig();
    agconnect.instance().configInstance(context);
}

export	{
	context
}


