/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-15 21:57:20
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-09-26 15:58:25
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/getUuid.js
 */
// import { encode,decode } from "js-base64";

let unique = 0;
export function buildUUID(prefix = "") {
    const time = Date.now();
    const random = Math.floor(Math.random() * 10);
    unique++;
    const number = 10 * time + random ;
    let uuid = unique.toString() + number.toString();
    let strNum = 0;
    let str = uuid;
    strNum = parseInt(str);
    // console.dir(strNum);
    str = strNum.toString(36);
    // console.dir(str)
    uuid = str;
    if(prefix)
        return prefix + '_' + uuid;
    else
        return uuid;
}

// console.dir(buildUUID('test'));