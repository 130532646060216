/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-02 19:20:57
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-11-12 12:29:10
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/auth.js
 */
import { context } from "@/assets/js/connectConfig";

import agconnect from "@agconnect/api";
import "@agconnect/instance";

import "@agconnect/auth";
import "@agconnect/function"

import * as cDB from "@/assets/js/cloudDatabase/cloudDatabase"
import * as cS from "@/assets/js/cloudStorage/cloudStorage"
import * as roleDB from "@/assets/js/cloudDatabase/sys_roleDB"
import * as userDB from "@/assets/js/cloudDatabase/sys_userDB"
import { sys_role } from "@/components/model/sys_role"
import { sys_user } from "@/components/model/sys_user"

import { buildUUID } from "@/assets/js/getUuid"
import Swal from "sweetalert2";

agconnect.instance().configInstance(context);

// import Swal from "sweetalert2";


export class AuthCrypt { }

AuthCrypt.prototype.encrypt = function (value) {
    return value + '---authEncrypt';
}

AuthCrypt.prototype.decrypt = function (value) {
    return value.split('---')[0];
}

export class Crypt {
}

Crypt.prototype.encrypt = function (value) {
    return value + '---encrypt';
}

Crypt.prototype.decrypt = function (value) {
    return value.split('---')[0];
}

// agconnect.instance().setCryptImp(new Crypt());

export function setAuthCryptImp(cryptImpl) {
    agconnect.auth().setCryptImp(cryptImpl);
}

export function setCryptImp(cryptImpl) {
    agconnect.instance().setCryptImp(cryptImpl);
}

/**
 * @description: Set where auth-related data is stored locally。0：indexedDB；1：sessionStorage；2：memory
 * @Date: 2022-09-04 19:02:35
 * @param {*} saveMode
 * @return {*}
 */
export function setUserInfoPersistence(saveMode) {
    agconnect.auth().setUserInfoPersistence(saveMode);
}


export function getUserInfo() {

    return agconnect.auth().getCurrentUser().then(user => {
        if (user) {
            //业务逻辑
            return { res: 1, user }
        }
        else {
            return { res: 0 }
        }
    });
}

export function getPhoneCredential(countryCode, account, password, verifyCode){
    let credential = agconnect.auth.PhoneAuthProvider.credentialWithVerifyCode(countryCode, account, password, verifyCode);
    return credential;
}

export function getEmailCredential(account, password, verifyCode){
    let credential = agconnect.auth.EmailAuthProvider.credentialWithVerifyCode(account, password, verifyCode);
    return credential;
}

export function getPhoneVerifyCode(countryCode, phoneNumber, sendInterval) {
    // console.dir(waitTime)

    return agconnect.auth().requestPhoneVerifyCode(countryCode,
        phoneNumber,
        agconnect.auth.Action.ACTION_REGISTER_LOGIN,
        'zh_CN',//发送验证码的语言
        sendInterval)
        .then(ret => {
            //验证码申请成功
            return { res: 1, ret };
        }).catch(error => {
            //验证码申请失败 
            return { res: 0, error };
            // Swal.fire({
            //     title: 'Error',
            //     text: '验证码申请失败',
            //     icon: 'error',
            //     confirmButtonColor: '#1E90FF'
            // })  
        });
}

export function getResetVerifyCode(countryCode, phoneNumber, sendInterval) {
    return agconnect.auth().requestPhoneVerifyCode(countryCode,
        phoneNumber,
        agconnect.auth.Action.ACTION_RESET_PASSWORD,// 重置密码时action需要传agconnect.auth.Action.ACTION_RESET_PASSWORD
        'zh_CN',//发送验证码的语言
        sendInterval)
        .then(ret => {
            //验证码申请成功
            return { res: 1, ret }
        }).catch(error => {
            //验证码申请失败 
            return { res: 0, error }
        })
}

export function getEmailVerifyCode(emailStr,sendInterval){
    return agconnect.auth().requestEmailVerifyCode(emailStr,
        agconnect.auth.Action.ACTION_REGISTER_LOGIN,
        'zh_CN',//发送验证码的语言
        sendInterval)//发送间隔时间
    .then(ret => {
        //验证码申请成功
        return { res: 1, ret }
    }).catch(error => {
        //验证码申请失败
        return { res: 0, error }
    });
}

export function getEmailResetVerifyCode(countryCode, phoneNumber, sendInterval) {
    return agconnect.auth().requestEmailVerifyCode(countryCode,
        phoneNumber,
        agconnect.auth.Action.ACTION_RESET_PASSWORD,// 重置密码时action需要传agconnect.auth.Action.ACTION_RESET_PASSWORD
        'zh_CN',//发送验证码的语言
        sendInterval)
        .then(ret => {
            //验证码申请成功
            return { res: 1, ret }
        }).catch(error => {
            //验证码申请失败 
            return { res: 0, error }
        })
}

export function register(countryCode, phoneNumber, password, verifyCode, name) {
    let phoneUser = new agconnect.auth.PhoneUser(countryCode, phoneNumber, password, verifyCode);
    return agconnect.auth().createPhoneUser(phoneUser)
        .then(async user => {
            //创建用户成功
            // let cloudDBZone = await cDB.init();
            // console.dir(user.getUser().getUid());
            await cS.cloudStorageInit();
            let e1 = await cS.getDownloadURL('publicFile/user/avart/logo.png');
            await cDB.init();
            // let Role = new sys_role();
            // Role.uid = await user.getUser().getUid();
            // Role.roleId = await "100";
            // Role.startDate = await new Date();
            // Role.dateLimit = await -1;
            // if(Role.uid)
            //     await roleDB.addNewRole(Role);
            let User = new sys_user();
            User.uid = await user.getUser().getUid();
            if(name)
                User.userName = name;
            else
                User.userName = await buildUUID("Ezpsy");
            if(e1.res)
                User.avart = e1.downloadURL
            else
                User.avart = "https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Fuser%2Favart%2Flogo.png?token=5e47ebdc-7319-4579-87c0-d1d097b86d27"
            if(User.uid)
                await userDB.addNewUser(User);
            await cDB.close();
            return { res: 1, user };
        }).catch(error => {
            //创建用户失败
            return { res: 0, error };
        });
}

export function loginByPhone(countryCode, phoneNumber, password, verifyCode) {
    let credential = '';
    if (verifyCode) {
        credential = agconnect.auth.PhoneAuthProvider.credentialWithVerifyCode(countryCode, phoneNumber, password, verifyCode);
    }
    else {
        credential = agconnect.auth.PhoneAuthProvider.credentialWithPassword(countryCode, phoneNumber, password);
    }

    return agconnect.auth().signIn(credential)
        .then(user => {
            //登录成功
            // console.dir(user);
            return { res: 1, user };
        }).catch(error => {
            //登录失败
            // console.dir(error)
            return { res: 0, error };
        });
}

export function registerByEmail(emailStr, password, verifyCode, name){
    let emailUser = new agconnect.auth.EmailUser(emailStr, password, verifyCode);
    return agconnect.auth().createEmailUser(emailUser)
        .then(async user => {
            //创建帐号成功后，默认已登录
            await cS.cloudStorageInit();
            let e1 = await cS.getDownloadURL('publicFile/user/avart/logo.png');
            await cDB.init();
            // let Role = new sys_role();
            // Role.uid = await user.getUser().getUid();
            // Role.roleId = await "100";
            // Role.startDate = await new Date();
            // Role.dateLimit = await -1;
            // if(Role.uid)
            //     await roleDB.addNewRole(Role);
            let User = new sys_user();
            User.uid = await user.getUser().getUid();
            if(name)
                User.userName = name;
            else
                User.userName = await buildUUID("Ezpsy");
            if(e1.res)
                User.avart = e1.downloadURL
            else
                User.avart = "https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Fuser%2Favart%2Flogo.png?token=5e47ebdc-7319-4579-87c0-d1d097b86d27"
            if(User.uid)
                await userDB.addNewUser(User);
            await cDB.close();
            // let avart = null;
            // if(e1.res)
            //     avart = e1.downloadURL
            // else
            //     avart = "https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Fuser%2Favart%2Flogo.png?token=5e47ebdc-7319-4579-87c0-d1d097b86d27"
            // let reqBody = {
            //     uid: user.getUser().getUid(),
            //     name: name,
            //     avart: avart
            // }
            // let reqBodyJson = JSON.stringify(reqBody)
            // console.dir(reqBodyJson);
            // let functionCallable = agconnect.function().wrap("upsert-user-$latest");
            // // console.dir(functionCallable);
            // functionCallable.timeout = 3000;
            // // let functionResult = 
            // await functionCallable.call(reqBodyJson+',');
            // console.dir(functionResult);
            // let returnValue = functionResult.getValue();
            // console.dir(returnValue);
            return { res: 1, user };
        }).catch(error => {
            //创建用户失败
            return { res: 0, error };
        });
}

export function loginByEmail(emailStr, password, verifyCode){
    let credential = '';
    if(verifyCode){
        credential = agconnect.auth.EmailAuthProvider.credentialWithVerifyCode(emailStr, password, verifyCode);
    }
    else{
        credential = agconnect.auth.EmailAuthProvider.credentialWithPassword(emailStr, password);
    }
    return agconnect.auth().signIn(credential)
         .then(user => {
            //登录成功
            return { res: 1, user };
         }).catch(error => {
            //登录失败
            return { res: 0, error };
         });
}

export function loginByAnonymousAccount() {
    return agconnect.auth().signInAnonymously().then(user => {
        //登录成功
        return { res: 1, user }
    }).catch(error => {
        //登录失败
        return { res: 0, error }
    });
}

export function logout(func){
    return agconnect.auth().signOut().then(async () => {
        //登出成功
        if(func)
            func()
    }).catch(error => {
        //登出失败
    });
}

export async function judgeIsLogin() {
    await getUserInfo().then(async e => {
        await console.dir(e.res);
        if (!e.res)
            await loginByAnonymousAccount().then(async ret => {
                // console.dir(ret);
                await console.dir("login success:")
                await console.dir(ret);
            })
        else{
            await console.dir("user already exists:");
            await console.dir(e.user);
        }
    })
}

export async function updatePhone(newPhone, newVerifyCode){
    let e = await getUserInfo();
    if(e.res)
    {
        let user = e.user;
        let data = await user.updatePhone(newPhone, newVerifyCode, "zh_CN");
        return { res: 1, data }
    }
    else{
        return { res: 0 }
    }
}

/**
 * @description: 
 * @Date: 2022-10-14 22:30:39
 * @param {string} newPassword
 * @param {string} verifyCode
 * @param {number} provide 手机: 11, 邮箱: 12
 * @return {*}
 */
export async function updatePassword(newPassword, verifyCode, provide){
    let e = await getUserInfo();
    if(e.res)
    {
        let user = e.user;
        let data = await user.updatePassword(newPassword, verifyCode, provide);
        return { res: 1, data }
    }
    else{
        return { res: 0 }
    }
}

export function resetPasswordByPhone(countryCode, phoneNumber, newPassword, verifyCode) {
    //此处无法使用+86, 原因未知
    return agconnect.auth().resetPasswordByPhone(countryCode, phoneNumber, newPassword, verifyCode).then(ret => {
        return { res: 1, ret }
    }).catch(e => {
        return { res: 0, e }
    })
}

export function resetPasswordByEmail(countryCode, emailNumber, newPassword, verifyCode) {
    //此处无法使用+86, 原因未知
    return agconnect.auth().resetPasswordByEmail(countryCode, emailNumber, newPassword, verifyCode).then(ret => {
        return { res: 1, ret }
    }).catch(e => {
        return { res: 0, e }
    })
}
    
export async function updateProfile(obj){
    // obj = {
    //     displayName:'***',
    //     photoUrl:'***',
    //   }; 
    let e=await getUserInfo()
    if(e.res)
    {
        let user = e.user;
        let data=await  user.updateProfile(obj)
        return { res: 1, data }
    } else{
        return { res: 0 }
    }
}

export async function reauthenticateByPhone(countryCode, phoneNumber, password, verifyCode) {
    let credential = getPhoneCredential(countryCode, phoneNumber, password, verifyCode)
    let e = await getUserInfo();
    if(e.res)
    {
        let user = e.user;
        if(!user.isAnonymous())
            return await user.userReauthenticate(credential);
    }
}

export async function reauthenticateByEmail(countryCode, emailNumber, password, verifyCode) {
    let credential = getEmailCredential(countryCode, emailNumber, password, verifyCode)
    let e = await getUserInfo();
    if(e.res)
    {
        let user = e.user;
        if(!user.isAnonymous())
            return await user.userReauthenticate(credential);
    }
}

export async function deleteUser(){
    let e = await agconnect.auth().deleteUser();
}