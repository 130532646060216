<script setup>
 import WOW from "wow.js";
 import 'animate.css/animate.compat.css'
 import 'animate.css'
import { onMounted } from 'vue'
const foot_text=[{url:require('@/assets/img/phone.png'),name:'电话',content:'(+086) 15056709773'},
        {url:require('@/assets/img/email.png'),name:'邮箱',content:'service@ezpsy.net'},
        {url:require('@/assets/img/address.png'),name:'地址',content:'安徽省合肥市瑶海区'},]
onMounted(() => {
      console.log(123)
      var wow = new WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 50, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: false, // act on asynchronously loaded content (default is true)
        callback: function (box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null, // optional scroll container selector, otherwise use window,
        resetAnimation: true, // reset animation on end (default is true)
      });
      wow.init();
    })

</script>
<template>
<div class="my_footer">
      <el-divider style="padding-top:5vh;width:80%;margin-left:10vw;top:5vh"></el-divider>
      
      <div v-for="item,index in foot_text" :key="index" class="f_flag wow animate__fadeInUp" >
        <img :src="item.url" alt="" style="width:20%; float:left; margin-top: 3vh;">
        <div style="float:right; width: 75%; text-align: left;">
          <h4 style="font-size:1.2vw;margin-bottom: 0;">{{item.name}}</h4>
        <p>{{item.content}}  </p>
        </div>
        
      </div>
     
      </div>
</template>
<style lang="scss">
.my_footer{
  background-color: black;
  width: 100%;
  height: 25vh;
  
}
.f_flag{
  width: 15vw;
  float: left;
  margin-left: 11vw;
  color: #f8f8f8;
}
</style>