/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-12 15:35:25
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-10-10 08:56:08
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/cloudDatabase/cloudDatabase.js
 */
import { context } from "@/assets/js/connectConfig";

import agconnect from "@agconnect/api";
import "@agconnect/instance"; 

import{AGConnectCloudDB,CloudDBZoneConfig,CloudDBZoneQuery} from "@agconnect/database";

agconnect.instance().configInstance(context);

let agcCloudDB;
let cloudDBZone;
export var isSuccess = false;

import schema from "@/components/config/ezpsyDB.json";

/**
 * @description: 数据库初始化
 * @Date: 2022-09-06 09:03:58
 * @return {*}
 */
export async function init() {

    AGConnectCloudDB.initialize(context);

    agcCloudDB = AGConnectCloudDB.getInstance();
    agcCloudDB.createObjectType(schema);
    console.dir("create CloudDB success:")
    console.dir(agcCloudDB);

    const config = new CloudDBZoneConfig('Ezpsy');
    cloudDBZone = await agcCloudDB.openCloudDBZone(config);
    console.dir("create cloudDBZone success:");
    console.dir(cloudDBZone);
    isSuccess = true;

}

export async function close() {
    agcCloudDB.closeCloudDBZone(cloudDBZone);
}

export async function addNewData(Data){
    try {
        const cloudDBZoneResult = await cloudDBZone.executeUpsert(Data);
        console.log('upsert ' + cloudDBZoneResult + ' record');
        return cloudDBZoneResult;
    } catch (e) {
        console.log(e);
    }
}

export async function getQuery(className,keyName,key){
    const query = await CloudDBZoneQuery.where(className).equalTo(keyName, key);
    return query;
}

export async function getQueryNews(className,keyName,num){
    const query = await CloudDBZoneQuery.where(className).orderByDesc(keyName).limit(num);
    return query;
}

export async function executeQueryAll(obj){
    try {
        const query = CloudDBZoneQuery.where(obj);
        const snapshot = await cloudDBZone.executeQuery(query);
        const resultArray = snapshot.getSnapshotObjects();
        console.log(resultArray);
        return resultArray;
    } catch(e) {
        console.log(e);
        return e;
    }
}

/**
 * @description: 查询方法
 * @Date: 2022-09-06 08:53:05
 * @param {*} cloudDBZoneQuery
 * @return {*}
 */
export async function executeQuery(cloudDBZoneQuery) {
    try {
        const snapshot = await cloudDBZone.executeQuery(cloudDBZoneQuery);
        const resultArray = snapshot.getSnapshotObjects();
        // console.log(resultArray);
        return resultArray;
    } catch (e) {
        // console.log(e);
        return e;
    }
}

/**
 * @description: 删除方法
 * @Date: 2022-09-06 10:14:14
 * @param {*} Role
 * @return {*}
 */
export async function executeDelete (Obj) {
    try {
        const num = await cloudDBZone.executeDelete(Obj);
        // console.log(num)
        return num;
    } catch (e) {
        console.log(e);
        return 0;
    }
}