/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-04 14:50:32
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-10-10 11:02:51
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/cloudDatabase/sys_roleDB.js
 */

import { sys_role } from "@/components/model/sys_role";
import * as cDB from "@/assets/js/cloudDatabase/cloudDatabase";

// let agcCloudDB;
// let cloudDBZone;

// export async function init(){ 
//     cloudDBZone = await cDB.init();
// }

// export async function close() {
//     agcCloudDB.closeCloudDBZone(cloudDBZone);
// }
    

/**
 * @description: 增加新角色
 * @Date: 2022-09-06 08:53:25
 * @param {*} Role
 * @return {*}
 */
export async function addNewRole(Role) {
    return await cDB.addNewData(Role);
}

/**
 * @description: 通过uid获取角色列表
 * @Date: 2022-09-12 15:17:54
 * @param {*} uid
 * @return {*}
 */
export async function getRoleListByUid(uid){
    const query = await cDB.getQuery(sys_role,'uid',uid);
    return await cDB.executeQuery(query);
}

/**
 * @description: 通过uid删除角色id
 * @Date: 2022-09-12 15:23:55
 * @param {*} uid
 * @return {*}
 */
export async function deleteRoleByUid(uid){
    // const query = await cDB.getQuery(sys_role,'uid',uid);
    // let RoleList = await cDB.executeQuery(query);
    // let res = '';
    // for(let i = 0;i < RoleList.length;i++){
    //     if(RoleList[i].roleId === roleId)
    //     {
    //         res = await cDB.executeDelete(RoleList[i]);
    //     }
    //     else
    //     {
    //         res = 0;
    //     }
    // }
    let role = new sys_role();
    role.uid = uid;
    let res = await cDB.executeDelete(role);
    
    return res;
}

export async function upgradeVip(sys_role){
    let res=await cDB.addNewData(sys_role);
    console.log(res)
    return res
}


// export async function updateRole(uid,roleId,startDate,dateLimit){
//     let Role = new sys_role();
//     Role.uid = uid;
//     Role.roleId = roleId;
//     return await addNewRole(Role)
// }