<!--
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-04 10:24:58
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-10-18 21:51:42
 * @Description: 导航栏组件
 * @FilePath: /ezpsy/src/components/NavibarPage.vue
-->
<template lang="">
    <nav class="navBox">
        <div class="logo">
            <img class="img" :src="imgSrc" />
            <a class="name">Ezpsy</a>
        </div>
        <nav class="nav">
            <a id="index" class="navibar txtDAnimate" href="/">首页</a>
            <a id="about" class="navibar txtDAnimate" href="/about.html">博客</a> <!-- |
            <a id="ezpsy" class="navibar" href="/ezpsy.html">Ezpsy</a> |
            <a id="personal" class="navibar" href="/personal.html">Personal</a> |
            <a id="test" class="navibar" href="/test.html">Test</a> -->
            <a class="console txtDAnimate" @click="toConsole()">控制台</a>
        </nav>
        
        <div class="userSign" v-show="!isLogin">
            <a class="userOpt login txtDAnimate" href="/login.html">Sign In</a>
            <a class="userOpt register" href="/register.html">Sign Up</a>
        </div>
        <div class="user" v-show="isLogin">
            <!-- <img ></img> -->
            <a class="userOpt userName txtDAnimate" href="/personal.html">{{userName}}</a>
            <a class="userOpt logout" @click="logout()">Sign Out</a>
        </div>
    </nav>
</template>
<script>
import { setStorage } from '@/assets/js/storage';
import * as Auth from "@/assets/js/auth"; 
import * as cDB from "@/assets/js/cloudDatabase/cloudDatabase";
import * as userDB from "@/assets/js/cloudDatabase/sys_userDB";
import Swal from 'sweetalert2';
// import { sys_user } from "@/components/model/sys_user";

export default {
    name: "NavibarPage",
    data() {
        return {
            isLogin: false,
            user: "",
            userName: "",
            imgSrc: require("@/assets/img/logo-150x150.png")
        }
    },
    async created(){
        await cDB.init();
        await setStorage();

        await Auth.getUserInfo().then(async e => {
            if(e.res){
                this.isLogin = !e.user.isAnonymous();
                // let user = await userDB.getUserListByUid(e.user.getUid());
                // this.user = user[0];
                // // console.dir(user);
                // try {
                //     this.userName = this.user.userName;
                // } catch (error) {
                //     // console.dir(error);
                // }
                let user = e.user;
                this.userName = user.getDisplayName();
                // console.dir(this.user);
            }
        })
    },
    unmounted(){
      cDB.close();
    },
    methods: {
        logout(){
            Auth.logout(async ()=>{
                await Swal.fire({
                    icon: 'success',
                    timer: 1000,
                    showConfirmButton: false,
                    title: '登出成功'
                })
                location.href = "/index.html"
            });
        },
        toConsole() {
            if(this.isLogin)
                location.href = "/personal.html"
            else
                location.href = "/login.html"
        }
    }
}
</script>
<style lang="scss" scoped>
// $navBoxWidth: 1250px;$navBoxHeight: 40px;$navBoxMarginCol: 30px;
// $userWidth: 160px;
// // $navWidth: $navBoxWidth - $userWidth;
// $navWidth: 1050px;
$navBoxWidth: 100%;$navBoxHeight: 60px;$navBoxMarginCol: 30px;$navbarML: 50px;
$userWidth: 10%;$userSignHeight: 40px;$userSignMT: ($navBoxHeight - $userSignHeight)/2;
$userMR: 2%;$userConWidth: 45%;
// $navWidth: $navBoxWidth - $userWidth;
$navWidth: 70%;$fontColor: #BBE2F0;

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.txtDAnimate {
    display: inline-block;
    position: relative;
    text-decoration: none;
    // color: #0087ca;
}
.txtDAnimate::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: $fontColor;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.txtDAnimate:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navBox {
    width: $navBoxWidth;
    height: $navBoxHeight;
    /* padding: 30px; */
    // margin: $navBoxMarginCol auto $navBoxMarginCol auto;
    line-height: $navBoxHeight;
    .logo{
        width: 15%;
        height: $navBoxHeight;
        float: left;
        // line-height: 60px;
        .img{
            width: 60px;
            height: 60px;
            vertical-align: middle
            // margin-top: 10px;
        }
        .name{
            margin-left: 20px;
            font-size: 24px;
            font-weight: 900;
            vertical-align: middle;
            background-image: linear-gradient(to bottom right,#FDC6D8,#BBE2F0,#FDC6D8);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
        }
    }
    .nav{
        width: $navWidth;
        /* padding: 10px; */
        float: left;
        .navibar {
            float: left;
            margin-left: $navbarML;
            font-weight: bold;
            color: #2c3e50;
        }
        .navibar:hover{
            // color: #42b983;
            color: $fontColor;
        }
        .console{
            float: right;
            font-weight: bold;
            color: #2c3e50;
            cursor: pointer;
        }
        .console:hover{
            // color: #42b983;
            color: $fontColor;
        }

        // .txtDAnimate{
        //     text-decoration: underline;
        //     text-decoration-color: $fontColor;
        //     -webkit-text-decoration-color: $fontColor;
        //     text-decoration-thickness: 3px;
        //     text-underline-offset: 5px;
            
        // }
    }
    .userSign{
        width: $userWidth;
        float: right;
        margin-right: $userMR;
        .userOpt{
            // width: $userWidth/2;
            width: $userConWidth;
            height: $navBoxHeight;
            font-weight: bold;
            color: #2c3e50;
            text-decoration: none;
        }
        .userOpt:hover{
            // color: #42b983;
            color: $fontColor;
        }
        .register{
            float: right;
            border: 2px solid #42b983;
            border-radius: 5px;
            box-sizing: border-box;
            line-height: $userSignHeight;
            height: $userSignHeight;
            margin-top: $userSignMT;
        }
        .register:hover{
            background: #42b983;
            color: white;
        }
        .login{
            float: left;
        }
    }
    .user{
        // width: $userWidth/2;
        width: $userWidth;
        height: $navBoxHeight;
        margin-right: $userMR;
        float: right;
        .userOpt{
            cursor: pointer;
            // width: $userWidth/2;
            width: $userConWidth;
            height: $navBoxHeight;
            font-weight: bold;
            color: #2c3e50;
            text-decoration: none;
        }
        .userOpt:hover{
            // color: #42b983;
            color: $fontColor;
        }
        .userName{
            // width: 3*$userWidth/4;
            float: left;
        }
        .logout{
            // width: $userWidth/4;
            height: $userSignHeight;
            line-height: $userSignHeight;
            margin-top: $userSignMT;
            float: right;
            border: 2px solid #42b983;
            border-radius: 5px;
            box-sizing: border-box;
        }
        .logout:hover{
            background: #42b983;
            color: white;
        }
    }
}
</style>