/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-06 15:08:31
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-10-24 09:28:30
 * @Description: 云存储初始化
 * @FilePath: /ezpsy/src/assets/js/cloudStorage/cloudStorage.js
 */
import { context } from "../connectConfig";

import agconnect from "@agconnect/api";
import "@agconnect/instance";

import "@agconnect/cloudstorage";

import * as Auth from "@/assets/js/auth";
import Swal from "sweetalert2";
import { buildUUID } from "../getUuid";


let storageManagement;
// let storageReference;
/**
 * @description: 初始化存储实例
 * @Date: 2022-09-06 15:34:26
 */
export function cloudStorageInit(){
    // let tagCN = 'cn';
    agconnect.instance().configInstance(context);

    // insCN;
    // insCN.setOption({ routePolicy: 1 });

    storageManagement = agconnect.cloudStorage();
    if(storageManagement)
    {
        console.dir("initialize storage succeed:");
        console.dir(storageManagement);
    }
    
}

/**
 * @description: 构建引用
 * @Date: 2022-09-06 22:41:33
 * @param {string} path 实例下的文件夹路径
 * @return {*}
 */
export function createStorageReference(path){
    let storageReference = "";
    // console.dir(path.split('://')[0]==='grs');
    if(path)
    {
        if(path.split('://')[0] === 'grs')
            storageReference = storageManagement.storageReferenceFromUrl(path);
        else
            storageReference = storageManagement.storageReference(path);
    }
    else{
        storageReference = storageManagement.storageReference(path);
    }
    
    if(storageReference)
    {
        console.dir("create reference succeed:");
        console.dir(storageReference);
        return storageReference;
    }
    else{
        return false
    }
}

/**
 * @description: 上传进度
 * @Date: 2022-09-06 22:42:45
 * @param {*} uploadTask
 */
function printUploadPercent (uploadTask) {
    uploadTask.on('state_changed', 
    function (snapshot) {
        if(!snapshot){
            console.log('Upload Result is null')
            return;
        }
        if(snapshot.totalByteCount == 0){
            console.log('Upload File is empty')
            return;
        }
        var progress = (snapshot.bytesTransferred / snapshot.totalByteCount) * 100
        console.log('Upload is ' + progress.toFixed(1) + '% done')
        switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused')
                break
            case 'running':
                console.log('Upload is running')
                break
            case 'success':
                console.log('Upload is success')
                break
            case 'canceled':
                console.log('Upload is canceled')
                break
            case 'error':
                console.log('Upload is error')
                break
        }
    }, 
    function (snapshot) {
        switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused')
                break
            case 'running':
                console.log('Upload is running')
                break
            case 'success':
                console.log('Upload is success')
                break
            case 'canceled':
                console.log('Upload is canceled')
                break
            case 'error':
                console.log('Upload is error')
                break
        }
    }, 
    function () {
        console.log('Upload is success')
        Swal.fire({
            icon: 'success',
            title: '上传成功',
            showConfirmButton: false,
            timer: 1000
        })
    })
}
/**
 * @description: 上传方法
 * @Date: 2022-09-06 22:39:51
 * @param {File} file 文件 
 * @param {string} folder  文件名
 * @param {string} uid  用户id(非匿名登录)
 */
export async function upload(file,folder,metadata,name){
    let storageReference = createStorageReference();
    let src = '';
    if(name)
        src = folder + "/" + name;
    else
        src = folder + "/" + file.name;
    if(folder){
        // console.dir(src);
        let reference = storageReference.child(src);
        let uploadTask = reference.put(file,metadata);
        await printUploadPercent(uploadTask);
    }
        
}

export function uploadString(str,folder,name){
    let storageReference = createStorageReference();
    let src = '';
    if(!name)
        name = buildUUID('xml')+'.xml';
    src = folder + '/' + name; 
    if(folder){
        // console.dir(src);
        let reference = storageReference.child(src);
        let uploadTask = reference.putString(str);
        printUploadPercent(uploadTask);
    }
}

/**
 * @description: 获取文件夹下的所有文件和目录
 * @Date: 2022-09-06 22:43:55
 * @param {string} folder 实例下的文件夹路径
 * @return {*}
 */
export function getFileListAll(folder){
    let storageReference = createStorageReference(folder);
    return storageReference.listAll()
    .then( ret => {
        // console.dir(ret);
        // console.log(ret)
        return {res: 1,ret}
    })
    .catch( error => {
        return { res: 0,error }
    } )
}

/**
 * @description: 删除文件
 * @Date: 2022-09-12 15:18:08
 * @param {*} path
 * @return {*}
 */
export function deleteFile(path){
    let storageReference = createStorageReference(path);
    return storageReference.delete()
    .then( ret=> {
        return { res: 1,ret }
    })
    .catch( err => {
        return { res: 0,err }
    })
}

/**
 * @description: 获取文件下载地址
 * @Date: 2022-09-12 15:14:05
 * @param {*} path
 * @return {*}
 */
export function getDownloadURL(path){
    let storageReference = createStorageReference(path);
    
    return storageReference.getDownloadURL()
    .then((downloadURL) => { 
        return { res: 1,downloadURL }
    })
    .catch((err) => { 
        return { res: 0,err }
    });
    
}

/**
 * @description: 上传私有文件
 * @Date: 2022-09-06 22:45:21
 * @param {File} file 文件
 * @param {string} folder 实例下的文件夹
 */
export function uploadPrivateFile(file,folder,name){
    Auth.getUserInfo().then(e => {
        if(e.res)
        {
            if(e.user.isAnonymous()){
                Swal.fire({
                    text: '请登录或注册账号',
                    icon: 'warning',
                    confirmButtonColor: "#1E90FF"
                })
            }
            else{
                upload(file,'privateFile/'+e.user.getUid()+'/'+folder,{
                    cacheControl: 'no-cache',
                    // contentDisposition: 'helloworld',
                    // contentEncoding: 'helloworld',
                    // contentLanguage: 'helloworld',
                    // contentType: 'helloworld',
                    // customMetadata: {
                    //     hello: 'kitty'
                    // }
                },name);
            }
        }
        else{
            Swal.fire({
                text: '请登录或注册账号',
                icon: 'warning',
                confirmButtonColor: "#1E90FF"
            })
        }
    })
}

export function uploadPrivateString(str,folder,name){
    Auth.getUserInfo().then(e => {
        if(e.res)
        {
            if(e.user.isAnonymous()){
                Swal.fire({
                    text: '请登录或注册账号',
                    icon: 'warning',
                    confirmButtonColor: "#1E90FF"
                })
            }
            else{
                uploadString(str,'privateFile/'+e.user.getUid()+'/'+folder,name);
            }
        }
        else{
            Swal.fire({
                text: '请登录或注册账号',
                icon: 'warning',
                confirmButtonColor: "#1E90FF"
            })
        }
    })
}

/**
 * @description: 获取私有文件夹下的所有文件和目录
 * @Date: 2022-09-06 22:46:10
 * @param {string} folder 实例下的文件夹路径
 * @return {*}
 */
export function getPrivateFileListAll(folder){
    return Auth.getUserInfo().then( e => {
        if(e.res)
        {
            if(e.user.isAnonymous()){
                Swal.fire({
                    text: '请登录或注册账号',
                    icon: 'warning',
                    confirmButtonColor: "#1E90FF"
                })
                return false;
            }
            else{
                return getFileListAll("privateFile/" + e.user.getUid() + "/" + folder + "/")
            }
        }
        else{
            Swal.fire({
                text: '请登录或注册账号',
                icon: 'warning',
                confirmButtonColor: "#1E90FF"
            })
            return false;
        }
    })
}

/**
 * @description: 删除私有文件夹下的文件
 * @Date: 2022-09-12 16:36:55
 * @param {*} folder
 * @param {*} fileName
 * @return {*}
 */
export function deletePrivateFile(folder,fileName){
    return Auth.getUserInfo().then( e => {
        if(e.res)
        {
            if(e.user.isAnonymous()){
                Swal.fire({
                    text: '请登录或注册账号',
                    icon: 'warning',
                    confirmButtonColor: "#1E90FF"
                })
                return false;
            }
            else{
                return deleteFile("privateFile/" + e.user.getUid() + "/" + folder + "/" + fileName)
            }
        }
        else{
            Swal.fire({
                text: '请登录或注册账号',
                icon: 'warning',
                confirmButtonColor: "#1E90FF"
            })
            return false;
        }
    })
}

export function deletePublicFile(folder,fileName){
    return Auth.getUserInfo().then( e => {
        if(e.res)
        {
            if(e.user.isAnonymous()){
                Swal.fire({
                    text: '请登录或注册账号',
                    icon: 'warning',
                    confirmButtonColor: "#1E90FF"
                })
                return false;
            }
            else{
                return deleteFile("publicFile/" + folder + "/" + fileName)
            }
        }
        else{
            Swal.fire({
                text: '请登录或注册账号',
                icon: 'warning',
                confirmButtonColor: "#1E90FF"
            })
            return false;
        }
    })
}

export async function judgePrivateFileIsExisted(fileName,folder,suffix){
    let res = await getPrivateFileListAll(folder);
    let result = false;
    if(!fileName.split('.')[1])
        fileName = fileName + suffix;
    // console.dir(`fileName: ${fileName}`);
    if(res.res)
    {
        let fileList = res.ret.fileList;
        for(let i = 0;i < fileList.length;i++)
        {
            if(fileName === fileList[i].name)
                result = true;
        }
    }
    return result;
}

export async function judgePublicFileIsExisted(fileName,folder,suffix){
    let res = await getPublicFileListAll(folder);
    let result = false;
    if(!fileName.split('.')[1])
        fileName = fileName + suffix;
    // console.dir(`fileName: ${fileName}`);
    if(res.res)
    {
        let fileList = res.ret.fileList;
        for(let i = 0;i < fileList.length;i++)
        {
            console.dir();
            if(fileName === fileList[i].name)
                result = true;
        }
    }
    return result;
}

export function uploadPublicFile(file,folder,name){
    Auth.getUserInfo().then(e => {
        if(e.res)
        {
            if(e.user.isAnonymous()){
                Swal.fire({
                    text: '请登录或注册账号',
                    icon: 'warning',
                    confirmButtonColor: "#1E90FF"
                })
            }
            else{
                upload(file,'publicFile/'+folder,{
                    cacheControl: 'no-cache',
                    // contentDisposition: 'helloworld',
                    // contentEncoding: 'helloworld',
                    // contentLanguage: 'helloworld',
                    // contentType: 'helloworld',
                    // customMetadata: {
                    //     hello: 'kitty'
                    // }
                },name);
            }
        }
        else{
            Swal.fire({
                text: '请登录或注册账号',
                icon: 'warning',
                confirmButtonColor: "#1E90FF"
            })
        }
    })
}

export function uploadPublicString(str,folder,name){
    Auth.getUserInfo().then(e => {
        if(e.res)
        {
            if(e.user.isAnonymous()){
                Swal.fire({
                    text: '请登录或注册账号',
                    icon: 'warning',
                    confirmButtonColor: "#1E90FF"
                })
            }
            else{
                uploadString(str,'publicFile/'+folder,name);
            }
        }
        else{
            Swal.fire({
                text: '请登录或注册账号',
                icon: 'warning',
                confirmButtonColor: "#1E90FF"
            })
        }
    })
}

/**
 * @description: 
 * @Date: 2022-09-14 22:11:43
 * @param {string} folder 文件夹
 * @return { res:0|1,ret|err }
 */
export function getPublicFileListAll(folder){
    console.log("getPublic")
    return getFileListAll("publicFile/" + folder + "/");
}

export function getPublicAllImg(path){
   
    return getPublicFileListAll(path).then(async res=>{
        var all_img=[];
        // console.log('res',res) 
        all_img=res.ret.fileList
        // console.dir(all_img[0].name);
        // getDownloadURL(all_img[i])
       
        var map=[]
        let i;
        for(i=0;i<all_img.length;i++){
          
           await getDownloadURL(all_img[i].path).then(res=>{
                    map.push(res.downloadURL) 
                    // localStorage.setItem(name,res.downloadURL)
            })
        }
        console.dir(map);
        return map;
       
   })
}

export async function deleteBeforeUpload(folder,uid){
    let list= await getFileListAll(folder)
    list=list.ret.fileList
    for(let i=0;i<list.length;i++){
        if(list[i].name.includes(uid)){
       await deleteFile(`${folder}/${list[i].name}`)
        }
    }
}

export async function deleteUserFile(uid){
    let ref = await createStorageReference(`privateFile/${uid}/`);
    let list = await ref.listAll()
    // console.dir(list);
    await deleteUserFiles(list);
}

export async function deleteUserFiles(list){
    for(let i = 0;i < list.fileList.length;i++)
    {
        await list.fileList[i].delete();
    }
    for(let i = 1;i < list.dirList.length;i++){
        let list0 = await list.dirList[i].listAll();
        // console.dir(list0);
        deleteUserFiles(list0);
    }
}