/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-04 18:38:22
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-09-25 20:50:51
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/storage.js
 */
// import agconnect from '@hw-agconnect/api';
// import '@hw-agconnect/storage';

import * as Auth from '@/assets/js/auth';
// import * as Storage from "@/assets/js/storage";
import { configInstance } from "@/assets/js/connectConfig"

// let storage = agconnect.instance().getService('AGCStorageService').getStorageInstance(0);

// function getSaveMode(key) {
//     return storage.get(key);
// }

// function setSaveMode(key, value) {
//     return storage.set(key, value);
// }
// import { agconnect } from "@/assets/js/connectConfig"
export function setStorage(){
    configInstance();
    
    Auth.setCryptImp(new Auth.Crypt());
    Auth.setAuthCryptImp(new Auth.AuthCrypt());

    Auth.setUserInfoPersistence(0);
}

// export { getSaveMode, setSaveMode };