/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-15 21:25:34
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-10-03 14:19:58
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/cloudDatabase/sys_userDB.js
 */

import { sys_user } from "@/components/model/sys_user";
import * as cDB from "@/assets/js/cloudDatabase/cloudDatabase";

export async function addNewUser(User) {
    return await cDB.addNewData(User);
}

export async function getUserListByUid(uid){
    const query = await cDB.getQuery(sys_user,'uid',uid);
    return await cDB.executeQuery(query);
}


export async function deleteUserByUid(uid){
    // const query = await cDB.getQuery(sys_user,'uid',uid);
    // let UserList = await cDB.executeQuery(query);
    // let res = await cDB.executeDelete(UserList[0]);
    let user = new sys_user();
    user.uid = uid;
    let res = await cDB.executeDelete(user);
    
    return res;
}

